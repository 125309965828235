import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import {WebConfigurationService} from './web-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private config: AppConfig, private storageService: StorageService, private webConfigurationService: WebConfigurationService) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.config.getEndpoint('oauth/auth'), {
      grant_type: 'password',
      username: username,
      password: password,
      client_id: 1,
    });
  }

  logout() {
    let access_token = localStorage.getItem('access_token');

    return this.http.post(this.config.getEndpoint('2fa/logout'), {
      access_token: access_token
    });
  }

  isLoggedIn(): boolean {
    let user = localStorage.getItem('currentUser');
    if (user) {
      return true;
    }
    return false;
  }

  isSuperAdmin(): boolean {
    const user = this.storageService.get('currentUser');

    return user && user.scope === 'read write superuser';
  }

  requestNewPassword(email: string) {
    return this.http.post(this.config.getEndpoint('user/password/retrieve'), {
      email,
    });
  }

  resetPassword({ password, token }: { password: string; token: string }) {
    return this.http.post(this.config.getEndpoint('user/password/set'), {
      password,
      token,
    });
  }

  generateEmail(email: string) {
    return this.http.post(this.config.getEndpoint('2fa/generate/email'), {
      user: email
    });
  }

  verifyEmail(email: string, password: string, token: string) {
    return this.http.post(this.config.getEndpoint('2fa/login'), {
      username: email,
      password: password,
      token: token
    })
  }

  refreshToken(refresh_token: string) {
    return this.http.post(this.config.getEndpoint('2fa/token/refresh'), {
      refresh_token: refresh_token
    })
  }
}
